/* eslint-disable no-useless-escape */
import React from 'react';
import CmsRenderer from '@core_modules/cms/components/cms-renderer';
import { COLORS } from '@core/theme/vars';

const FooterV1 = (props) => {
    const {
        data, loading, storeConfig,
    } = props;
    return (
        <>
            <div className="cms-container wrapper-footer">
                {!loading ? <CmsRenderer content={data.cmsBlocks.items[0].content} storeConfig={storeConfig} /> : null}
                <style jsx global>
                    {`
                        .footer-top .prose > p > a {
                            font-size: 13px;
                            color: ${COLORS.neutral[500]};
                            font-weight: 600;
                            letter-spacing: .35px;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        .newsletter-form .swift-newsletter-wrapper {
                            padding: 0;
                        }
                        #newsletter-validate-detail > div {
                            flex-basis: 100%;
                        }
                        .right-section-newsletter {
                            flex-basis: 100%;
                            width: 100%;
                        }
                        .newsletter-form .swift-newsletter-container {
                            border: none;
                        }
                        .newsletter-form .swift-newsletter-field {
                            width: 100%;
                            border-radius: 0;
                            border-color: ${COLORS.neutral[150]};
                            height: auto;
                        }
                        .newsletter-form .swift-newsletter-field>input {
                            padding-top: 7px;
                            padding-bottom: 7px;
                        }
                        .block-newsletter button {
                            border-radius: 0;
                            padding-top: 5px;
                            padding-bottom: 5px;
                            background-color: ${COLORS.neutral.white};
                            border: 1px solid ${COLORS.neutral.black};
                            &:hover {
                                background-color: ${COLORS.neutral.black};
                                & > span > span { 
                                    color: ${COLORS.neutral.white} !important;
                                }
                            }
                        }
                        .block-newsletter button > span > span {
                            color: ${COLORS.neutral.black} !important;
                            text-transform: uppercase;
                            font-size: 13px;
                            font-weight: 700;
                            letter-spacing: .33px;
                            line-height: 18px;
                            position: relative;
                            top: 1px;
                        }
                        .footer-links a {
                            display: block;
                            text-decoration: none;
                            &:hover {
                                color: ${COLORS.primary.DEFAULT};
                                text-decoration: underline;
                            }
                        }
                        .payment-options .mgz-row {
                            column-gap: 15px;
                            row-gap: 10px;
                        }
                        .payment-options .mgz-row .mgz-column {
                            flex-basis: fit-content;
                        }
                        .magezon-heading {
                            position: relative;
                            z-index: 1;
                        }
                        .footer-bottom {
                            background-color: #f7f7f7;
                        }
                        .payment-heading [class*="text"] {
                            font-weight: 700;
                            line-height: 1.2;
                            font-size: 18px;
                            color: ${COLORS.neutral.black};
                            letter-spacing: .33px;
                        }
                        .customer-heading .prose p {
                            font-size: 2.188rem;
                            line-height: 52px;
                            color: ${COLORS.neutral.black};
                            font-weight: 700;
                            margin: 0;
                            padding-left: 58px;
                        }
                        .customer-links .mgz-row {
                            align-items: center;
                        }
                        .footer-bottom .prose {
                            font-size: 15px;
                            color: ${COLORS.neutral[500]}
                        }
                        .social-links {
                            padding-left: 20px;
                        }
                        .social-links .mgz-column {
                            flex-basis: fit-content;
                        }
                        .social-links .magezon-icon .magezon-icon-inner i {
                            font-size: 2.125rem;
                            margin-right: 15px;
                        }
                        .social-links .magezon-icon .magezon-icon-inner {
                            width: fit-content !important;
                            height: auto !important;
                        }
                        .customer-links .prose > p > a {
                            color: ${COLORS.neutral.black};
                            font-size: 18px;
                            font-weight: 600;
                            letter-spacing: 0.45px;
                            line-height: 26px;
                            text-decoration: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        .customer-heading .prose p:first-of-type {
                            font-size: 1.125rem;
                            line-height: 1.625rem;
                            margin-bottom: -7px;
                            letter-spacing: 2.52px;
                        }
                        .text-opening-hours .prose p {
                            letter-spacing: .33px;
                            line-height: 1.43;
                        }
                        .customer-heading:before {
                            content: '';
                            background: url(/assets/img/ui.svg) center no-repeat;
                            height: 77px;
                            width: 87px;
                            display: inline-block;
                            position: absolute;
                            left: 10px;
                            top: 13px;
                            z-index: 0;
                        }
                        .newsletter-heading {
                            position: relative;
                        }
                        .newsletter-heading [class*="text"] {
                            font-size: 2.5rem;
                            line-height: 59px;
                            color: ${COLORS.neutral.black};
                            margin-top: 6px;
                            font-weight: 700;
                        }
                        .footer-bottom .text-newsletter .prose > p {
                            letter-spacing: .33px;
                            line-height: 1.43;
                        }
                        .newsletter-heading:after {
                            content: '';
                            background: url(/assets/img/ui-mail.svg) center no-repeat;
                            height: 46px;
                            width: 57px;
                            display: inline-block;
                            position: absolute;
                            left: 185px;
                            top: 13px;
                            z-index: 0;
                        }
                        .footer-links-heading [class*="text"] {
                            font-size: 1.875rem;
                            line-height: 36px;
                            color: ${COLORS.neutral.black};
                            margin-bottom: 6px;
                            font-weight: 700;
                            letter-spacing: .33px;
                        }
                        .footer-links-heading:before {
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            font-size: 16px;
                            line-height: inherit;
                            color: ${COLORS.yellow[300]};
                            content: "\e945";
                            font-family: var(--icons-urbanicon-fossil-theme);
                            vertical-align: middle;
                            display: inline-block;
                            font-weight: 400;
                            overflow: hidden;
                            speak: none;
                            text-align: center;
                            position: absolute;
                            left: -10px;
                            top: 30px;
                            z-index: 0;
                        }
                        .customer-heading .prose {
                            position: relative;
                            z-index: 1;
                        }
                        .footer-links .prose > p {
                            margin-bottom: 15px;
                            margin-top: 0;
                        }
                        .footer-links .prose > p > a {
                            font-size: 15px;
                            line-height: 22px;
                            color: ${COLORS.neutral.black};
                            letter-spacing: .45px;
                        }
                        .footer-links br {
                            content: '';
                            display: block;
                            margin-top: 10px;
                            line-height: 22px;
                        }
                        footer img {
                            margin-top: auto !important;
                            margin-bottom: auto !important;
                            display: inline;
                        }
                        .footer-top span {
                            display: inline !important;
                        }
                        .mgz-items-center .mgz-row{
                            align-items: center;
                        }
                        .footer-top .mgz-items-center {
                            padding-left: 10px;
                        }
                        .text-follow-us .prose p {
                            letter-spacing: .33px;
                        }
                    `}
                </style>
            </div>
        </>
    );
};

FooterV1.propTypes = {};

export default FooterV1;
